<template>
<b-card :title="'Bulk Print Warranty - DO Number '+this.sapDo">
  <b-col cols="12">
    Select all
    <b-form-checkbox
      @change="selectToggle"
      style="display: inline"
      v-model="selectAll">
    </b-form-checkbox>
    <b-table
      :busy.sync="busyLoading"
      :fields="fields"
      :items="inventories"
      show-empty>
      <template #cell(actions)="row">
        <b-form-checkbox
          style="display: inline"
          v-model="row.item.selected"
          :id="'checkbox-' + row.index">
        </b-form-checkbox>
      </template>
      <template #cell(sapDo)="row">
        {{ row.item.sapDo === "" ? "-" : row.item.sapDo }}
      </template>

      <template #cell(barcode)="row">
        {{ scanningStatus(row.item).barcode }}
      </template>

      <template #cell(serial)="row">
        {{ scanningStatus(row.item).serial }}
      </template>

      <template #cell(createdAt)="row">
        {{ row.item.createdAt | formatDate }}
      </template>

      <template #cell(warrantyStatus)="row">

        {{ row.item.warrantyStatus }}

      </template>
    </b-table>
  </b-col>
  <b-col
    cols="12"
    class="mb-2">
    <div class="filter-responsive">
      <b-button
        @click="bulkPrintWarrantyInstruction()"
        variant="primary"
        :disabled="!canPrint">Bulk Print by DO</b-button>
    </div>
  </b-col>
</b-card>
</template>

<script>
import {
  header
} from "../../delivery-module/actions-detail/daikin-img-base64";
import {
  mapActions,
  mapGetters
} from "vuex";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from "pdfmake/build/pdfmake";

export default {
  data() {
    return {
      daikinQR: require('@/assets/images/drcc/qr-code-ewarranty.png'),
      UATdaikinQR: require('@/assets/images/drcc/qr-uat-ewarranty.png'),
      daikinHeader: require('@/assets/images/drcc/Daikin_Header.png'),
      inventories: [],
      selectedInventories:[],
      dealerCode: "",
      selectAll: false,
      busyLoading:true,
      fields: [{
          key: "sapDo",
          label: "DO Number",
          filterable: true,
          sortable: true
        },
        {
          key: "itemModel",
          label: "Model",
          filterable: true,
          sortable: true
        },
        {
          key: "barcode",
          label: "Barcode",
          filterable: true,
          sortable: true
        },
        {
          key: "warrantyStatus",
          label: "Warranty Status",
          filterable: true,
          sortable: true,
          thStyle: {
            minWidth: '170px'
          }
        },

        {
          key: "serial",
          label: "Serial",
          filterable: true,
          sortable: true
        },
        {
          key: "createdAt",
          label: "DO Date",
          filterable: true,
          sortable: true,
        },
        {
          key: "actions",
          label: "To Print"
        },
      ],

    };
  },
  watch: {
    selectAll(newVal) {
      //console.info('select all watch ',newVal)
      this.inventories.forEach(item => {
        //console.info(item.selected)
        item.selected = newVal;
      });
    }
  },
  mounted() {
    // console.log('DC',this.currentDealer.dealerCode);
  },
  created() {
    
    document.title = "Print Bulk Warranty by DO | RSP";
    this.sapDo = this.$route.params.sapDo;

    if (this.sapDo == undefined || this.sapDo == "") {
      this.$router.push('/warranty/status');
    }

    //get current dealer
    this.$store.dispatch("dealer/getCurrentDealer").then((x) => {
      // console.log(x)

      if (x == null) {
        this.getAllInventoriesDealer({
          bulk:'true',
          sapDo: this.sapDo,
        }).then((data) => {
          if (data.data.length <= 0) {
            this.$router.push('/warranty/status');
          }

          this.busyLoading = false
          this.inventories = data.data

          //console.info(this.inventories)
        });
        return;
      }

      if (x.dealerCode !== null || x.dealerCode !== undefined) {
        this.dealerCode = x.dealerCode;
      }

      this.getAllInventoriesDealer({
        sapDo: this.sapDo,
        dealerCode: this.dealerCode,
        bulk:'true',
      }).then((data) => {
        if (data.data.length <= 0) {
          this.$router.push('/warranty/status');
        }
        this.busyLoading = false
        this.inventories = data.data
      });
    });
  },
  methods: {
    ...mapActions({
      getAllInventoriesDealer: "inventory/getAllInventoriesDealer",
      getCurrentDealer: "dealer/getCurrentDealer",
    }),
    selectToggle() {
      //test   
    },
    async bulkPrintWarrantyInstruction() {
      var baseURL = config.EWARRANTY_URL || "https://ewarranty.daikin.com.sg"

      console.info('bulk warranty list ', this.inventories)
      const toDataURL = (url) =>
        fetch(url)
        .then((response) => response.blob())
        .then(
          (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
        );
      // let header = this.daikinHeader;
      let qrcode = (config.IS_UAT==undefined)?this.daikinQR:this.UATdaikinQR;
      let header64;
      let qrcode64;
      await toDataURL(header).then((res) => {
        header64 = res;
      });
      await toDataURL(qrcode).then((res) => {
        qrcode64 = res;
      });
      let inventoryArray = {};
      try {
        //TODO get all barcode selected data
        // console.log('res Axios',resImg)
        // console.log('resData',resData);
        inventoryArray = this.inventories.filter((x) => {
          return x.selected
        })
      } catch (error) {
        console.error(error)
      }
      let pdfContent = []
      let docDefinition = {
        content: []
      }

      for (let i = 0; i < inventoryArray.length; i++) {
        // console.log("pro", getData);

        let model = inventoryArray[i].itemModel;
        let serial = inventoryArray[i].serial;
        let barcode = inventoryArray[i].barcode;
        let dealerCode = inventoryArray[i].dealerCode;
        let DONumber = inventoryArray[i].sapDo;
        let distChan = inventoryArray[i].industryType ? inventoryArray[i].industryType : ''

        let listChannel = ['DL', 'DR', 'DS', 'M1', 'M2', 'M3']
        let type = listChannel.includes(distChan) ? 'Route' : 'Project'

        // var pdfMake = require("pdfmake/build/pdfmake.js");
        if (pdfMake.vfs == undefined) {
          // var pdfFonts = require("./customVfs.js");
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }

        pdfContent.push({
            text: `WARRANTY REGISTRATION CARD \n`,
            color: '#0ABDFF'
          }, {
            text: `valid in Singapore only \n`,
            color: '#0ABDFF',
            fontSize: 10
          }, {
            text: `\n`
          },

          {
            columns: [{
                text: `Register online at ${baseURL} \nGo Paperless, Build a Greener Future with Daikin\n`
              },
              {
                image: `${qrcode64}`,
                width: 75,
                height: 75,
                absolutePosition: {
                  x: 425,
                  y: 110
                }
                // margin:[120,0,0,0],
                // fit:[75,75]
              }
            ]
          },

          {
            text: `\n`
          }, {
            text: `\n`
          }, {
            columns: [{
                text: `Model No: ${model}\n\nSerial No: ${serial}\n\nBarcode No: ${barcode}`
              },
              {
                text: `Dealer: ${dealerCode}\n\nWarranty Type: ${type}\n\nDO No: ${DONumber}`
              }
            ]
          },

          {
            text: `\n`
          }, {
            text: `\n`
          },

          {
            text: `Important: \n`,
            bold: true
          }, {
            text: `Please register within 14 days from installation date. \n`
          }, {
            text: `A digital warranty certificate will be generated upon successful registration. \n`
          }, {
            text: `You can view your certificate through ${baseURL}/viewwarranty\n`
          }, {
            text: `For any warranty enquiry, please call us at 65838888 ext 2196\n`
          },

          {
            text: `\n`
          },

          {
            text: '                   Thank you for choosing Daikin, the World’s Leading Air conditioning Manufacturer                ',
            color: '#ffffff',
            background: '#0AAEFF',
            alignment: 'center',

          },

        )
        if (i < inventoryArray.length - 1) {
          pdfContent.push({
            text: '',
            pageBreak: 'after'
          })

        }
      }

      // optimal width header 500
      docDefinition = {
        pageMargins: [50, 100, 50, 50],
        header: function (currentPage, pageCount, pageSize) {
          return [{
            image: `${header}`,
            alignment: "center",
            width: 500,
            margin: [0, 50, 0, 0],
          }, ];
        },
        content: pdfContent,
        defaultStyle: {
          fontSize: 11,
        }
      };

      pdfMake
        .createPdf(docDefinition)
        .download(`Daikin Warranty Registration Card - DO ${this.sapDo}.pdf`);
    },

    scanningStatus(row) {
      let barcode = row.barcode;
      let serial = row.serial;
      let status;
      if (row.skip === true) {
        barcode = "Skip Scan";
        serial = "Skip Scan";
      }

      if (row.scanStatus === "Not Scan" && row.unscanUser !== undefined) {
        barcode = "-";
        status = "Unscanned";
      }
      if (row.scanStatus === "Scan") {
        status = "Scan";
      }
      if (row.scanStatus === "Not Scan" && row.unscanUser === undefined) {
        barcode = "-";
        serial = "-";
        status = "Not Scan";
      }

      return {
        barcode,
        serial,
        status,
      };
    },
    getDealer(row) {
      let named = row;
      if (row === null || row === undefined || row === "") {
        named = {
          dealerName: "Not Found"
        };
      }
      return named;
    },
    getWarehouse(row) {
      let named = "Not Found";
      if (row === "1001") {
        named = "Daikin";
      }
      if (row === "1003") {
        named = "Sumitomo";
      }
      return named;
    },
  },
  computed: {
    ...mapGetters({
      items: "inventory/filterInventoryDealer",
    }),
    currentDealer() {
      //ini buat cek apakah akun yang sedang login termasuk dealer atau bukan
      return this.$store.getters["dealer/getCurrentDealers"];
    },
    canPrint() {
      if (this.inventories.length <= 0) {
        return false
      }
      if (this.inventories.findIndex((x) => {
          return x.selected && x.selected !== "false"
        }) <= -1) {
        return false
      }
      return true;
    }
  },
};
</script>

<style>
.filter-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filter-responsive button {
  margin: 0 0 0 5px;
}

@media screen and (max-width: 480px) {
  .filter-responsive {
    flex-direction: column;
    justify-content: none;
  }

  .filter-responsive button {
    margin: 5px 0 0 0;
    width: 100px;
  }
}
</style>
